import "@nestoca/ui/style";
import "@shared/ui/styles/global.scss";
import "react-toastify/dist/ReactToastify.css";

import React, {
  ReactElement,
  ReactNode,
  Suspense,
  useEffect,
  useState,
} from "react";

import { datadogRum } from "@datadog/browser-rum";
import { appWithTenant, useTenant } from "@nestoca/multi-tenant";
import { toastDefaultOptions } from "@nestoca/ui";
import { setBackendBaseUrl, setCommonHeaders } from "@shared/api/client";
import { Datadog } from "@shared/datadog";
import { MaintenanceProvider } from "@shared/ui";
import { Layout } from "@shared/ui/components/layout";
import { SSREnv } from "@shared/utils/server-side-env";
import {
  DehydratedState,
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import App, { AppProps, AppContext } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import {
  appWithTranslation,
  SSRConfig as I18nNextSSRConfig,
} from "next-i18next";
import ReactDOM from "react-dom";
import { ToastContainer } from "react-toastify";

import { ErrorBoundary } from "@components/error-boundary";
import { PrimarySidebarContent } from "@components/sidebar-content";
import { AuthProvider } from "@lib/auth-provider";
import { UserProvider } from "@lib/user-provider";

import type { SSRConfig as MultiTenantSSRConfig } from "@nestoca/multi-tenant";
import type { SupportedLocales } from "@shared/constants";
import type { ServideSideBackendApiProps } from "@shared/utils/common-server-props";
import type { NextPage } from "next";

export type NextPageWithLayout<P = unknown> = NextPage<P> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

interface PageProps
  extends I18nNextSSRConfig,
    MultiTenantSSRConfig,
    SSREnv,
    ServideSideBackendApiProps {
  dehydratedState: DehydratedState;
}

type AppPropsWithLayout<P = Record<string, unknown>> = AppProps<P> & {
  Component: NextPageWithLayout;
};

const isDev = process.env.NODE_ENV === "development";

// import mocking utils
if (process.env.NEXT_PUBLIC_API_MOCKING === "enabled") {
  require("@test/msw/next");
}

const TOAST_DEFAULT_OPTIONS = {
  ...toastDefaultOptions,
  closeOnClick: true,
  autoClose: 5000,
};

const newQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      retry: false,
      // @see https://github.com/TanStack/query/issues/5679
      networkMode: "offlineFirst",
    },
    mutations: {
      // @see https://github.com/TanStack/query/issues/5679
      networkMode: "offlineFirst",
    },
  },
});

function MyApp({ Component, pageProps }: AppPropsWithLayout<PageProps>) {
  const { enableMaintenanceFad, enableMaintenance } =
    pageProps._tenant?.tenant?.featureFlags || {};

  useEffect(() => {
    if (process.env.NODE_ENV !== "production") {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const axe = require("@axe-core/react");
      axe(React, ReactDOM, 1000);
    }
  });

  const router = useRouter();
  const { rid, accountRid } = router.query;
  if (rid || accountRid)
    setCommonHeaders({
      "x-login-on-behalf": (rid as string) || (accountRid as string),
    });

  const tenant = useTenant();
  if (tenant) {
    setBackendBaseUrl(tenant.apiBaseUrl);
    datadogRum.setGlobalContextProperty("tenant", tenant);
  }

  const [queryClient] = useState(() => newQueryClient);

  const getLayout =
    Component.getLayout ||
    ((page) => (
      <main>
        <Layout primarySidebarContent={<PrimarySidebarContent />}>
          {page}
        </Layout>
      </main>
    ));

  return (
    <Datadog
      enabled={!isDev}
      sessionReplayRecording
      applicationId={process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID || ""}
      clientToken={process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN || ""}
      site="datadoghq.com"
      service={process.env.NEXT_PUBLIC_DATADOG_SERVICE || "Financial-Dashboard"}
      env={pageProps._env}
      version={process.env.NEXT_PUBLIC_DATADOG_VERSION || "latest"}
      defaultPrivacyLevel="mask-user-input"
      allowedTracingUrls={[
        (requestUrl = "") => {
          const url = new URL(requestUrl);

          // return false if the pathname start with `/napi` or `/_next`
          // we don't want to trace those requests
          // `/_next` is the nextjs internal path for static files
          // `/napi` is the path for the next version checker every N minutes
          const excludedPaths = [
            `${router.basePath}/napi`,
            `${router.basePath}/_next`,
          ];
          const isExcludedPath = excludedPaths.some((path) =>
            url.pathname.startsWith(path)
          );

          if (isExcludedPath) {
            return false;
          }

          const backendURL = new URL(pageProps.baseApiUrl);
          // we allow tracing for the backend url and the current window location (this frontend service on our K8S cluster)
          // allowed origin ["https://app.{env}.nesto.ca", "{scheme}://{env}.tenant-domain.tld"]
          // by default with this logic `tenant?.publicDomain` is not in the list and not allowed
          // ex: `tenant?.publicDomain` = `https://auth.tenant-domain.tld`
          const allowedOrigins = [backendURL.origin, window.location.origin];
          const isAllowedOrigin = allowedOrigins.includes(url.origin);

          return isAllowedOrigin;
        },
      ]}
      trackUserInteractions
      trackResources
    >
      <ErrorBoundary>
        <Head>
          <meta
            name="viewport"
            content="width=device-width,minimum-scale=1,initial-scale=1"
          />
          <link
            rel="icon"
            href={
              tenant?.logo?.secondary?.[router.locale as SupportedLocales]?.url
            }
          />
          <title>Financial Dashboard</title>
        </Head>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <Suspense fallback={null}>
              <MaintenanceProvider
                isEnabled={enableMaintenance || enableMaintenanceFad}
              >
                <AuthProvider rid={(rid as string) || (accountRid as string)}>
                  <UserProvider rid={(rid as string) || (accountRid as string)}>
                    {getLayout(<Component {...pageProps} />)}
                  </UserProvider>
                </AuthProvider>
              </MaintenanceProvider>
            </Suspense>
            <ToastContainer {...TOAST_DEFAULT_OPTIONS} />
          </Hydrate>
          <ReactQueryDevtools position="bottom-right" />
        </QueryClientProvider>
      </ErrorBoundary>
    </Datadog>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const { query } = appContext.ctx;
  const { applicationId } = query;
  const appProps = await App.getInitialProps(appContext);
  return { ...appProps, applicationId };
};

// TODO: improve the types here `Type 'AppPropsWithLayout<PageProps>' does not satisfy the constraint 'AppProps'.`
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default appWithTenant<AppPropsWithLayout<PageProps>>(
  appWithTranslation<AppPropsWithLayout<PageProps>>(MyApp)
);
