export interface Product {
  id: number;
  productId: number;
  name: string;
  productName?: string;
  type: ProductType;
  term: ProductTerm;
  amortization: Amortization;
  amortizationMax: Amortization;
  rate: number;
  bestRate: number;
  insurable: boolean;
  insurance: InsuranceType;
  lenderId: number;
  lenderName: string;
  lenderType: LenderType;
  purpose: ProductPurpose;
}

export const PRODUCT_TYPES = ["FIXED", "VARIABLE", "HELOC"] as const;

export const PRODUCT_TYPE_FILTERS = ["FIXED", "VARIABLE", "NONE"] as const;

export type ProductType = (typeof PRODUCT_TYPES)[number];

export type ProductTerm =
  | "6_MONTHS"
  | "1_YEAR"
  | "2_YEAR"
  | "3_YEAR"
  | "4_YEAR"
  | "5_YEAR"
  | "6_YEAR"
  | "7_YEAR"
  | "10_YEAR"
  | "HELOC";

export type Amortization = 15 | 20 | 25 | 30;

export type InsuranceType = "INSURED" | "CONVENTIONAL";

export type ProductPurpose =
  | "OWNER_OCCUPIED"
  | "OWNER_OCCUPIED_AND_RENTAL"
  | "RENTAL"
  | "SECONDARY_RESIDENCE";

export type LenderType = "BANK" | "MONOLINE" | "CREDIT_UNION" | "ALTERNATE";
